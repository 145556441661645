<template>
  <!-- 货物品类类型 -->
  <div>
    <div class="box">
      <!-- 左边 -->
      <div class="boxleft glo_Table">
        <!-- 按钮列表 -->
        <div class="glo_btnList" ref="btnList2">
          <!-- <el-input
            style="margin-bottom: 10px"
            clearable
            placeholder="请输入分组名查询"
            @keyup.enter.native="queryBtn_Left"
            v-model="groupNameKey"
          ></el-input>
          <queryBtns
            style="margin-bottom: 15px"
            :MoreConditions="MoreConditions"
            :showMoreBtns="false"
            :showcancelBtns="false"
            @clerBtns="clerBtns"
            @queryBtn_ok="queryBtn_Left"
            @moreParam="moreParam"
          ></queryBtns> -->
          <div class="glo_btnList_left">
            <el-button @click="AddGroup()" class="glo_btnItems glo_bgcMainColor"
              >新建品类</el-button
            >
          </div>
        </div>
        <el-table
          :data="menuTableData"
          style="width: 100%"
          row-key="itemCategoryId"
          border
          :height="leftHeight"
          default-expand-all
          @row-click="rowClickLeft"
          :tree-props="{
            children: 'childCategorys',
            hasChildren: 'hasChildren',
          }"
        >
          <el-table-column prop="itemCategoryName" label="货物品类" width="160">
          </el-table-column>

          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small">
                <span class="tablebut" @click="AddGroup(scope.row)">新建</span>
                <span class="tablebut" @click="menumodify(scope.row)"
                  >修改</span
                >
                <span
                  class="tablebut"
                  @click="delBtns(scope.row.itemCategoryId)"
                  >删除</span
                >
                <span
                  v-if="scope.row.isShow == 0"
                  class="tablebut"
                  style="color: #d7d7d7"
                  @click="qiYong(scope.row.itemCategoryId)"
                  >启用</span
                >
                <span
                  v-if="scope.row.isShow == 1"
                  class="tablebut"
                  style="color: green"
                  @click="jinYong(scope.row.itemCategoryId)"
                  >禁用</span
                >
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 右边 -->
      <div class="boxright">
        <!-- 按钮列表 -->
        <div class="glo_btnList" ref="btnList">
          <div class="glo_btnList_left">
            <el-button @click="addServe()" class="glo_btnItems glo_bgcMainColor"
              >新增品名</el-button
            >
            <el-button @click="editServe()" class="glo_btnItems"
              >修改品名</el-button
            >
            <el-button class="glo_btnItems" @click="qiYongMore()"
              >启用
            </el-button>
            <el-button class="glo_btnItems" @click="jinYongMore()"
              >禁用
            </el-button>
            <el-button @click="delMore()" class="glo_btnItems">删除</el-button>
          </div>

          <queryBtns
            :MoreConditions="MoreConditions"
            :showMoreBtns="false"
            @clerBtns="clerBtns"
            @queryBtn_ok="queryBtn_ok"
            @moreParam="moreParam"
          ></queryBtns>
        </div>
        <!-- 查询条件 -->
        <div ref="queryForm">
          <el-form
            :model="queryData"
            class="glo_form hasMoreParam"
            label-position="left"
          >
            <el-form-item class="queryItems" label="一级品类:">
              <el-select
                v-model="queryData.itemCategoryName"
                clearable
                placeholder="请输入关键词"
                filterable
                remote
                :loading="false"
                @change="changeTypeName_q"
              >
                <el-option
                  v-for="item in menuTableData"
                  :key="item.itemCategoryCode"
                  :label="item.itemCategoryName"
                  :value="item.itemCategoryName"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="queryItems"
              label="二级品类:"
              v-if="ChildmenuTableData.length > 0"
            >
              <el-select
                v-model="ChilditemCategoryName"
                clearable
                placeholder="请输入关键词"
                filterable
                remote
                :loading="false"
                @change="changeTypeName1"
              >
                <el-option
                  v-for="item in ChildmenuTableData"
                  :key="item.itemCategoryCode"
                  :label="item.itemCategoryName"
                  :value="item.itemCategoryName"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item class="queryItems" label="品类:">
              <el-cascader
                v-model="queryKeyList"
                clearable
                filterable
                :props="propsVal"
                :show-all-levels="false"
                :options="menuTableData"
                @change="handleChange"
              ></el-cascader>
            </el-form-item> -->
            <el-form-item class="queryItems" label="品名:">
              <el-input
                clearable
                placeholder="请输入"
                @keyup.enter.native="queryBtn_ok"
                v-model="queryData.itemName"
              ></el-input>
            </el-form-item>

            <el-form-item class="queryItems" label="状态:">
              <el-select
                v-model="queryData.isShow"
                clearable
                placeholder="请输入关键词"
                filterable
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in isShowList"
                  :key="item.val"
                  :label="item.name"
                  :value="item.val"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>

        <!-- 表格 -->
        <div class="glo_Table" ref="tableWrapper">
          <el-table
            :height="tableHeigth"
            ref="mytable"
            @row-click="rowclick"
            :data="tableData"
            stripe
            border
            @selection-change="handleSelectionChange"
          >
            <el-table-column fixed="left" label="序号" type="index" width="60">
            </el-table-column>
            <el-table-column fixed="left" type="selection" width="60">
            </el-table-column>
            <template v-for="(item, index) in myTableHeard">
              <el-table-column
                :key="index"
                :label="item.name"
                :prop="item.field"
                sortable
                :min-width="item.width"
                :data-isTotal="item.isTotal"
                v-if="item && item.field == 'goodsStatusShow'"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div
                    style="color: #ef7c0a"
                    v-if="scope.row.goodsStatusShow == '上架'"
                  >
                    {{ scope.row.goodsStatusShow }}
                  </div>
                  <div v-else>{{ scope.row.goodsStatusShow }}</div>
                </template>
              </el-table-column>
              <el-table-column
                :key="index"
                :label="item.name"
                :prop="item.field"
                sortable
                :min-width="item.width"
                :data-isTotal="item.isTotal"
                v-else-if="item && item.isShow"
                show-overflow-tooltip
              >
              </el-table-column>
            </template>
            <el-table-column fixed="right" label="操作" min-width="200px">
              <template slot-scope="scope">
                <div class="tabRightBtns">
                  <el-button class="rbtns" @click.stop="editServe(scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    class="rbtns"
                    @click.stop="qiYongMore(scope.row.itemNameId)"
                    >启用</el-button
                  >
                  <el-button
                    class="rbtns"
                    @click.stop="jinYongMore(scope.row.itemNameId)"
                    >禁用</el-button
                  >
                  <el-button
                    class="rbtns"
                    @click.stop="delMore(scope.row.itemNameId)"
                    >删除</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!-- 分页组件 -->
        <paging
          ref="pags"
          :pageNum="pageStart"
          :total="total"
          :sizeList="sizeList"
          :size="pageTotal"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        ></paging>
      </div>
    </div>

    <!--        自定义表头           -->
    <!-- <sorttable
      :show_sortTableHeard="show_sortTableHeard"
      :allCheck="allCheck"
      :sortName="sortName"
      :tabelHeadeTitle="tabelHeadeTitle"
      :originalTabelHeadeTitle="originalTabelHeadeTitle"
      :selectLength="selectLength"
      @cancelSortHeard="cancelSortHeard"
      @confirmOk="confirmOk"
      @checktab="checktab"
      @checkAll="checkAll"
      @closeDias="closeDias"
    >
    </sorttable> -->

    <!-- 新建/编辑商品 -->
    <myDialog
      :diaWidth="diaWidth"
      :dialogTitle="dialogTitle"
      :isShowDialog="isShowDialog"
      @handleCloseDia="handleCloseDiaR"
      @diaCancelBtn="handleCloseDiaR"
      @diaConfirmBtn="diaConfirmBtnR('dialogFormRef')"
    >
      <div class="glo_dialogForm">
        <el-form
          class="diaForm"
          v-if="isShowDialog"
          ref="dialogFormRef"
          :model="dialogForm"
          label-width="100px"
          :rules="rulesForm"
        >
          <el-form-item
            class="dialogFormItems"
            prop="itemNameCode"
            label="品名编号:"
          >
            <el-input
              placeholder="不填则自动生成"
              v-model.trim="dialogForm.itemNameCode"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="dialogFormItems"
            prop="itemName"
            label="品名名称:"
          >
            <el-input
              placeholder="请输入"
              v-model.trim="dialogForm.itemName"
            ></el-input>
          </el-form-item>

          <el-form-item class="dialogFormItems" prop="" label="所属品类:">
            <el-select
              v-model="dialogForm.itemCategoryCode"
              clearable
              placeholder="请选择"
              filterable
              @change="changeTypeName2"
            >
              <el-option
                v-for="item in menuTableData"
                :key="item.itemCategoryCode"
                :label="item.itemCategoryName"
                :value="item.itemCategoryCode"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            class="dialogFormItems"
            label="二级品类:"
            v-if="ChildmenuTableData2.length > 0"
          >
            <el-select
              v-model="ChilditemCategoryName2"
              clearable
              placeholder="请输入关键词"
              filterable
              remote
              :loading="false"
              @change="changeTypeNameC"
            >
              <el-option
                v-for="item in ChildmenuTableData2"
                :key="item.itemCategoryCode"
                :label="item.itemCategoryName"
                :value="item.itemCategoryCode"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="dialogFormItems" prop="isShow" label="状态:">
            <el-select
              filterable
              clearable
              v-model="dialogForm.isShow"
              placeholder="请选择"
            >
              <el-option
                v-for="item in isShowList"
                :key="item.val"
                :label="item.name"
                :value="item.val"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="dialogFormItems" prop="sort" label="排序:">
            <el-input v-model="dialogForm.sort"></el-input>
          </el-form-item>

          <div
            class="langCategory"
            v-for="(itemA, indexA) in dialogForm.itemNameShow"
            :key="itemA.languageCode"
          >
            <el-form-item
              class="dialogFormItems"
              prop="languageCode	"
              label="语言:"
            >
              <el-select
                filterable
                clearable
                v-model="itemA.languageCode"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in langCodeList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              class="dialogFormItems"
              prop="showName"
              label="展示值:"
            >
              <el-input
                clearable
                placeholder="请输入"
                v-model.trim="itemA.showName"
              ></el-input>
            </el-form-item>

            <i
              v-if="indexA == 0"
              @click="addLangs2"
              class="el-icon-circle-plus"
              style="font-size: 30px"
            ></i>
            <i
              v-if="dialogForm.itemNameShow.length !== 1"
              @click="delLangs2(indexA)"
              class="el-icon-remove"
              style="font-size: 30px"
            ></i>
          </div>

          <el-form-item class="dislogFormTextarea" prop="comment" label="备注:">
            <el-input
              type="textarea"
              rows="2"
              v-model="dialogForm.comment"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </myDialog>

    <!-- 新建分组 -->
    <myDialog
      diaWidth="40%"
      :dialogTitle="dialogTitle_fz"
      :isShowDialog="isShowDialog_fz"
      @handleCloseDia="handleCloseDiaR_fz"
      @diaCancelBtn="handleCloseDiaR_fz"
      @diaConfirmBtn="diaConfirmBtnR_fz('dialogFormRef')"
    >
      <div class="glo_dialogForm">
        <el-form
          class="diaForm"
          v-if="isShowDialog_fz"
          ref="dialogFormRef"
          :model="dialogForm_fz"
          label-width="100px"
          :rules="rulesForm_fz"
        >
          <el-form-item
            class="dialogFormItems"
            prop="itemCategoryName"
            label="货物品类名称:"
          >
            <el-input
              clearable
              placeholder="请输入"
              v-model.trim="dialogForm_fz.itemCategoryName"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="dialogFormItems"
            prop="itemCategoryCode"
            label="品类代码:"
          >
            <el-input
              clearable
              placeholder="请输入"
              v-model.trim="dialogForm_fz.itemCategoryCode"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="dialogFormItems"
            prop="parentId	"
            label="上级品类:"
          >
            <el-select
              filterable
              clearable
              v-model="dialogForm_fz.parentId"
              placeholder="请选择"
            >
              <el-option
                v-for="item in menuTableData"
                :key="item.itemCategoryId"
                :label="item.itemCategoryName"
                :value="item.itemCategoryId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="dialogFormItems" prop="sort" label="排序:">
            <el-input
              clearable
              type="number"
              placeholder="请输入"
              v-model.trim="dialogForm_fz.sort"
            ></el-input>
          </el-form-item>

          <div
            class="langCategory"
            v-for="(itemA, indexA) in dialogForm_fz.showList"
            :key="itemA.languageCode"
          >
            <el-form-item
              class="dialogFormItems"
              prop="languageCode	"
              label="语言:"
            >
              <el-select
                filterable
                clearable
                v-model="itemA.languageCode"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in langCodeList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              class="dialogFormItems"
              prop="showName"
              label="展示值:"
            >
              <el-input
                clearable
                placeholder="请输入"
                v-model.trim="itemA.showName"
              ></el-input>
            </el-form-item>

            <i
              v-if="indexA == 0"
              @click="addLangs"
              class="el-icon-circle-plus"
              style="font-size: 30px"
            ></i>
            <i
              v-if="dialogForm_fz.showList.length !== 1"
              @click="delLangs(indexA)"
              class="el-icon-remove"
              style="font-size: 30px"
            ></i>
          </div>

          <el-form-item
            prop="comment"
            label="备注:"
            style="width: 100%; margin-right: 20px"
          >
            <el-input
              clearable
              type="textarea"
              placeholder="请输入"
              v-model="dialogForm_fz.comment"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </myDialog>
  </div>
</template>


<script>
import paging from "@/components/pagings.vue";
import queryBtns from "@/components/queryBtns.vue";
import myDialog from "@/components/Dialog.vue";
// import sorttable from "@/components/sortTemplate.vue"; //自定义排序表头
import uploadimgs from "@/components/upLoadImgs.vue";
import { Api } from "@/assets/js/api";
export default {
  inject: ["reload"],
  components: {
    paging,
    queryBtns,
    myDialog,
    // sorttable,
    uploadimgs,
  },
  data() {
    return {
      propsVal: {
        children: "childCategorys",
        label: "itemCategoryName",
        value: "itemCategoryName",
      },
      queryKeyList: [],
      ChildmenuTableData: [],
      ChilditemCategoryName: "",
      ChildmenuTableData2: [],
      ChilditemCategoryName2: "",

      dialogFormData: {
        conflictTypeId: [],
        isShow: "1",
        parentId: "",
        remark: "",
        sort: "1",
        typeName: "",
      },
      rules: {
        typeName: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
        isShow: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
      },
      PopUpTitle: "", //弹窗标题
      menushowBOX: false, //菜单弹窗
      menuTableData: [],
      diaWidth: "40%",
      //\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

      queryData: {
        itemName: "",
        isShow: 1,
        itemCategoryName: "",
      },

      // ================
      pageStart: 1, //页码
      total: 0, //总条数
      sizeList: [10, 20, 50, 100, 200],
      pageTotal: this.common.pageTotals, //一页几条
      nextPage: false, //是否有下一页
      show_sortTableHeard: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      myTableHeard: [], //我的表头
      originalTabelHeadeTitle: [],
      selectLength: 0, //显示的长度
      allCheck: false, //全选
      leftHeight: "75vh",
      tableHeigth: "75vh",
      tabelHeadeTitle: [
        {
          name: "编号",
          field: "itemNameCode",
          width: "100",
          sort: 1,
          isShow: true, //是否展示
          isTotal: false, //是否开启合计
          isSort: true, //表格设置里面是否可以排序
        },
        {
          name: "品名",
          field: "itemName",
          width: "100",
          sort: 1,
          isShow: true, //是否展示
          isTotal: false, //是否开启合计
          isSort: true, //表格设置里面是否可以排序
        },
        {
          name: "货物品类",
          field: "itemCategoryName",
          width: "100",
          sort: 1,
          isShow: true, //是否展示
          isTotal: false, //是否开启合计
          isSort: true, //表格设置里面是否可以排序
        },
        {
          name: "排序",
          field: "sort",
          width: "80",
          sort: 1,
          isShow: true, //是否展示
          isTotal: false, //是否开启合计
          isSort: true, //表格设置里面是否可以排序
        },
        {
          name: "状态",
          field: "isShowShow",
          width: "100",
          sort: 1,
          isShow: true, //是否展示
          isTotal: false, //是否开启合计
          isSort: true, //表格设置里面是否可以排序
        },
        {
          name: "备注",
          field: "comment",
          width: "100",
          sort: 1,
          isShow: true, //是否展示
          isTotal: false, //是否开启合计
          isSort: true, //表格设置里面是否可以排序
        },
        {
          name: "新建人",
          field: "createUserName",
          width: "100",
          sort: 1,
          isShow: true, //是否展示
          isTotal: false, //是否开启合计
          isSort: true, //表格设置里面是否可以排序
        },
        {
          name: "新建时间",
          field: "createTime",
          width: "100",
          sort: 1,
          isShow: true, //是否展示
          isTotal: false, //是否开启合计
          isSort: true, //表格设置里面是否可以排序
        },
        {
          name: "修改人",
          field: "updateUserName",
          width: "100",
          sort: 1,
          isShow: true, //是否展示
          isTotal: false, //是否开启合计
          isSort: true, //表格设置里面是否可以排序
        },
        {
          name: "修改时间",
          field: "updateTime",
          width: "100",
          sort: 1,
          isShow: true, //是否展示
          isTotal: false, //是否开启合计
          isSort: true, //表格设置里面是否可以排序
        },
      ],
      tableData: [],
      tableDataALL: [],
      totalArr: [], //需要合计的字段
      className: "", //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      sortName: "货物品类类型模板", //排序名
      pageGroupName: "GoodsCategoryType", //页面标识
      selectTableData: [], //选中的数据

      deperIds: [],
      MoreConditions: "更多条件", //  输入框绑定动态class
      isHaseMore: false, //false == 更多条件  true ==收起条件
      dialogType: "add", //add==新建   edit==修改

      parentList: [], //上级
      conflictTypeIds: [], //原来的冲突品类类型

      // ==== 弹窗组件属性 start ====
      isShowDialog: false,
      dialogTitle: "弹窗标题",
      diaWidth: "40%",
      dialogForm: {
        // 弹窗表单数据
        itemNameShow: [
          {
            languageCode: "zh-CN",
            showName: "",
            operationType: "ADD",
          },
          {
            languageCode: "cht",
            showName: "",
            operationType: "ADD",
          },
          {
            languageCode: "en",
            showName: "",
            operationType: "ADD",
          },
          {
            languageCode: "id",
            showName: "",
            operationType: "ADD",
          },
          {
            languageCode: "uz",
            showName: "",
            operationType: "ADD",
          },
          {
            languageCode: "ru",
            showName: "",
            operationType: "ADD",
          },
        ],
        comment: "",
        isShow: 1,
        itemCategoryCode: "",
        itemName: "",
        itemNameCode: "",
        sort: 1,
      },
      rulesForm: {
        itemCategoryCode: [
          { required: true, message: "请选择", trigger: ["blur", "change"] },
        ],
        itemName: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
      },

      isShowList: [
        {
          name: "启用",
          val: 1,
        },
        {
          name: "禁用",
          val: 0,
        },
      ],
      goodsStatusList: [
        {
          name: "上架",
          val: "shang_jia:goods_status",
        },
        {
          name: "下架",
          val: "xia_jia:goods_status",
        },
      ],
      dialogTypeR: "add",
      // 分组弹窗
      dialogTitle_fz: "分组名称",
      isShowDialog_fz: false,
      dialogTypeR_fz: "",
      rulesForm_fz: {
        groupName: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
        sort: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
      },
      dialogForm_fz: {
        showList: [
          {
            languageCode: "zh-CN",
            showName: "",
            operationType: "ADD",
          },
          {
            languageCode: "cht",
            showName: "",
            operationType: "ADD",
          },
          {
            languageCode: "en",
            showName: "",
            operationType: "ADD",
          },
          {
            languageCode: "id",
            showName: "",
            operationType: "ADD",
          },
          {
            languageCode: "uz",
            showName: "",
            operationType: "ADD",
          },
          {
            languageCode: "ru",
            showName: "",
            operationType: "ADD",
          },
        ],
        sort: "",
        itemCategoryCode: "",
        itemCategoryName: "",
        parentId: "",
        comment: "",
      },
      groupNameKey: "",

      fileNameLists: [], //商品主图
      fileNameLists2: [], //商品图片

      platform_type_list: [], //第三方平台类
      goods_status_list: [], //商品状态

      langCodeList: [
        {
          code: "zh-CN",
          name: "中文(简体)",
        },
        {
          code: "cht",
          name: "中文(繁体)",
        },
        {
          code: "en",
          name: "英文",
        },
        {
          code: "id",
          name: "印尼语",
        },
        {
          code: "uz",
          name: "乌兹别克语",
        },
        {
          code: "ru",
          name: "俄语",
        },
      ],
      delLangedList: [],
    };
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle = arr;
    }
  },
  watch: {
    queryKeyList(a, b) {
      console.log(a, "queryKeyList");
    },
  },
  created() {
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      );
    }
    this.myTableHeard = this.tabelHeadeTitle;
    // this.getGroupFindFieldSort(); //获取用户自定义表头

    this.endtime = this.Tools.getCurrentDate(); //今天的时间;
    this.sarttime = this.Tools.getFlexDate(-3); //三天前
    this.times = [this.sarttime, this.endtime];

    // 左边列表
    this.leftTableData();
  },
  mounted() {
    const that = this;
    // this.getStatusValList("goods_v2.goods_status");
    // this.getStatusValList("goods_v2.platform_type");
  },
  activated() {
    this.$nextTick(() => {
      this.fetTableHeight();
    });
  },
  watch: {},
  methods: {
    handleChange(e) {
      console.log(e, "e");
    },
    // 新建语言(品类)
    addLangs() {
      this.dialogForm_fz.showList.push({
        languageCode: "",
        showName: "",
        operationType: "ADD",
      });
    },
    delLangs(idx) {
      let arrs = JSON.parse(JSON.stringify(this.dialogForm_fz.showList));
      if (arrs[idx].operationType == "UPDATE") {
        let val = arrs[idx];
        val.operationType = "DEL";
        this.delLangedList.push(val);
      }
      arrs.splice(idx, 1);
      this.dialogForm_fz.showList = arrs;
    },
    // 新建语言(品名)
    addLangs2() {
      this.dialogForm.itemNameShow.push({
        languageCode: "",
        showName: "",
        operationType: "ADD",
      });
    },
    delLangs2(idx) {
      let arrs = JSON.parse(JSON.stringify(this.dialogForm.itemNameShow));
      arrs.splice(idx, 1);
      this.dialogForm.itemNameShow = arrs;
    },

    changeUrl(e) {
      let val = e;
      let arrs = [];
      if (val.indexOf("?") !== -1) {
        this.dialogForm.goodsIdNumber = "";

        arrs = val.split("?");
        // 提取1688的商品id
        if (arrs[0].indexOf("/offer/") !== -1) {
          let arr1688 = arrs[0].split("/offer/");
          let arr1688id = arr1688[1].split(".")[0] || "";
          this.dialogForm.goodsIdNumber = arr1688id;
          console.log("arr1688", arr1688);
        }

        // 淘宝/天猫提取id
        arrs = arrs[1].split("&");

        let Lists = [];
        arrs.forEach((item) => {
          let arr2 = item.split("=");
          let obj = {
            key: arr2[0],
            val: arr2[1],
          };
          Lists.push(obj);
        });
        Lists.forEach((item2) => {
          if (item2.key == "id") {
            this.dialogForm.goodsIdNumber = item2.val;
          }
        });
      }
    },
    changeFileList(array) {
      this.fileNameLists = array;
    },
    changeFileList2(array) {
      this.fileNameLists2 = array;
    },

    getStatusValList(type) {
      this.$comAPI.getStatusValList(type).then((res) => {
        // 第三方平台类型; this.getStatusValList("goods_v2.platform_type")
        if (type == "goods_v2.platform_type") {
          this.platform_type_list = res || [];
        }

        // 商品状态  this.getStatusValList("goods_v2.goods_status")
        if (type == "goods_v2.goods_status") {
          this.goods_status_list = res || [];
        }
      });
    },

    // 新建分组
    AddGroup(row) {
      console.log("row", row);
      if (row) {
        this.dialogForm_fz.parentId = row.parentId;
      }
      this.dialogTypeR_fz = "add";
      this.isShowDialog_fz = true;
      this.dialogTitle_fz = "新建分组";
    },
    /***
     * ====== 弹窗组件方法 ======
     */
    //  弹窗关闭事件(左上角的x和关闭按钮都算)
    handleCloseDiaR() {
      this.isShowDialog = false;
      this.ChilditemCategoryName2 = "";
      this.dialogForm = {
        // 弹窗表单数据
        itemNameShow: [
          {
            languageCode: "zh",
            showName: "",
            operationType: "ADD",
          },
          // {
          //   languageCode: "en",
          //   showName: "",
          //   operationType: "ADD",
          // },
          // {
          //   languageCode: "id",
          //   showName: "",
          //   operationType: "ADD",
          // },
        ],
        comment: "",
        isShow: 1,
        itemCategoryCode: "",
        itemName: "",
        itemNameCode: "",
        sort: 1,
      };
      this.fileNameLists = [];
      this.fileNameLists2 = [];
    },
    //  弹窗确认保存事件
    diaConfirmBtnR(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let param = {
            comment: this.dialogForm.comment || "",
            isShow: this.dialogForm.isShow || 0,
            itemCategoryCode: this.ChilditemCategoryName2
              ? this.ChilditemCategoryName2
              : this.dialogForm.itemCategoryCode || "",
            itemName: this.dialogForm.itemName || "",
            itemNameCode: this.dialogForm.itemNameCode || "",
            itemNameShow: this.dialogForm.itemNameShow || [],
            sort: this.dialogForm.sort || 0,
          };
          if (this.dialogTypeR == "add") {
            // 新建
            param.sign = this.Tools.getSign(param);
            this.$API.addItemName(param).then((res) => {
              this.$message.success(res.data.message || "新建成功");
              this.getData();
              this.isShowDialog = false;
            });
          } else {
            // 编辑
            param.itemNameId = this.dialogForm.itemNameId || "";
            param.sign = this.Tools.getSign(param);
            this.$API.updateItemName(param).then((res) => {
              this.$message.success(res.data.message || "修改成功");
              this.getData();
              this.isShowDialog = false;
            });
          }
        }
      });
    },
    //  关闭弹窗按钮事件(关闭按钮)
    diaCancelBtn() {
      this.isShowDialog = false;
    },

    //  弹窗关闭事件(左上角的x和关闭按钮都算)
    handleCloseDiaR_fz() {
      this.isShowDialog_fz = false;
      this.dialogForm_fz = {
        showList: [
          {
            languageCode: "zh",
            showName: "",
            operationType: "ADD",
          },
          // {
          //   languageCode: "en",
          //   showName: "",
          //   operationType: "ADD",
          // },
          // {
          //   languageCode: "id",
          //   showName: "",
          //   operationType: "ADD",
          // },
        ],
        sort: "",
        itemCategoryCode: "",
        itemCategoryName: "",
        parentId: "",
        comment: "",
      };
      this.delLangedList = [];
    },
    //  弹窗确认保存事件
    diaConfirmBtnR_fz(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let arrs = [...this.dialogForm_fz.showList, ...this.delLangedList];
          let param = {
            sort: this.dialogForm_fz.sort || 0,
            showList: arrs,
            itemCategoryCode: this.dialogForm_fz.itemCategoryCode || "",
            itemCategoryName: this.dialogForm_fz.itemCategoryName || "",
            parentId: this.dialogForm_fz.parentId || "",
            comment: this.dialogForm_fz.comment || "",
          };
          if (this.dialogTypeR_fz == "add") {
            // 新建
            param.sign = this.Tools.getSign(param);
            this.$API.addItemCategoryV2(param).then((res) => {
              this.$message.success(res.data.message || "新建成功");
              this.leftTableData();
              this.isShowDialog_fz = false;
            });
          } else {
            // 编辑
            param.itemCategoryId = this.dialogForm_fz.itemCategoryId || "";
            param.sign = this.Tools.getSign(param);
            this.$API.updateItemCategory(param).then((res) => {
              this.$message.success(res.data.message || "修改成功");
              this.leftTableData();
              this.isShowDialog_fz = false;
            });
          }
        }
      });
    },

    // 新建
    addServe() {
      this.isShowDialog = true;
      this.dialogTitle = "添加品名";
      this.dialogTypeR = "add";
    },
    // 修改
    editServe(row) {
      let data = {};
      if (row) {
        data = row;
      } else {
        if (this.selectTableData.length != 1) {
          this.$message.warning("请选择一条需要操作的数据");
          return false;
        }
        data = this.selectTableData[0];
      }
      Api.getItemNameDetail({ itemNameId: data.itemNameId }).then((res) => {
        let results = res.data.result || [];
        if (!results.showList) {
          results.showList = [
            {
              languageCode: "zh",
              showName: "",
              operationType: "ADD",
            },
            // {
            //   languageCode: "en",
            //   showName: "",
            //   operationType: "ADD",
            // },
            // {
            //   languageCode: "id",
            //   showName: "",
            //   operationType: "ADD",
            // },
          ];
        }
        results.itemNameShow = results.showList;
        results.showList.forEach((item) => {
          item.operationType = "UPDATE";
        });

        this.menuTableData.forEach((itemA) => {
          if (itemA.itemCategoryCode == results.itemCategoryCode) {
            // 一级品类
          } else if (itemA.childCategorys) {
            itemA.childCategorys.forEach((itemB) => {
              if (itemB.itemCategoryCode == results.itemCategoryCode) {
                this.ChildmenuTableData2 = itemA.childCategorys || [];

                this.ChilditemCategoryName2 = itemB.itemCategoryCode;
                results.itemCategoryCode = "";
                console.log(
                  "this.ChildmenuTableData2",
                  this.ChildmenuTableData2
                );
              }
            });
          }
        });

        // results.showList.forEach(item=>{
        //   item.operationType='UPDATE'
        // })
        this.dialogForm = results;
        this.isShowDialog = true;
        this.dialogTitle = "编辑品名";
        this.dialogTypeR = "edit";
      });
    },

    /***
     * ====== 弹窗组件方法 ======
     */
    // 编辑
    editorServe(row) {
      console.log(row);
      //   this.dialogForm = row;

      this.$API
        .getGoodsDetail({
          goodsId: row.goodsId,
        })
        .then((res) => {
          this.isShowDialog = true;
          this.dialogTitle = "编辑商品";
          this.dialogTypeR = "edit";
          this.dialogForm = res.data.result;

          // 主图
          let obj1 = {
            url: this.dialogForm.mainPicUrl,
            name: this.dialogForm.mainPicId,
          };
          this.fileNameLists = [obj1];

          // 图片
          let picVos = res.data.result.picVos || [];
          picVos.forEach((item) => {
            let obj = {
              url: item.fileUrl,
              name: item.fileId,
            };
            this.fileNameLists2.push(obj);
          });
        });
    },

    // 删除
    DelServe_R(id) {
      this.$confirm("是否删除所选数据!", "提示:")
        .then((res) => {
          let ids = [id];
          let param = {
            goodsIds: ids,
          };
          param.sign = this.Tools.getSign(param);
          this.$API.delGoods(param).then((res) => {
            this.$message.success(res.data.message || "操作成功");
            this.getData();
          });
        })
        .catch(() => {});
    },

    // =======================================

    // 查询条件
    changeTypeName_q(e) {
      this.ChildmenuTableData = [];
      this.menuTableData.forEach((item) => {
        if (item.itemCategoryName == e) {
          this.ChildmenuTableData = item.childCategorys || [];
          if (this.ChildmenuTableData.length == 0) {
            this.ChilditemCategoryName = "";
          }
        }
      });
      this.queryBtn_ok();
      //
    },

    changeTypeName1(e) {
      if (e) {
        this.ChilditemCategoryName = e;
      } else {
        this.ChilditemCategoryName = "";
      }
      this.queryBtn_ok();
    },

    changeTypeName2(e) {
      this.ChildmenuTableData2 = [];
      this.menuTableData.forEach((item) => {
        if (item.itemCategoryCode == e) {
          this.ChildmenuTableData2 = item.childCategorys || [];
          if (this.ChildmenuTableData2.length == 0) {
            this.ChilditemCategoryName2 = "";
          }
        }
      });
      this.queryBtn_ok();
      //
    },
    changeTypeNameC(e) {
      if (e) {
        this.ChilditemCategoryName2 = e;
      } else {
        this.ChilditemCategoryName2 = "";
      }
    },

    // 批量启用
    qiYongMore(id) {
      let ids = [];
      if (id) {
        ids.push(id);
      } else {
        if (this.selectTableData.length < 1) {
          this.$message.warning("请选择需要操作的数据");
          return false;
        }
        ids = this.selectTableData.map((item) => {
          return item.itemNameId;
        });
      }

      this.$confirm("是否启用所选品名?", "提示:")
        .then((respon) => {
          let param = {
            itemNameIds: ids,
          };
          param.sign = this.Tools.getSign(param);
          this.$API.startItemName(param).then((res) => {
            this.$message.success(res.data.message || "上架成功");
            // this.leftTableData();
            this.getData();
          });
        })
        .catch((error) => {});
    },
    // 批量下架
    jinYongMore(id) {
      let ids = [];
      if (id) {
        ids.push(id);
      } else {
        if (this.selectTableData.length < 1) {
          this.$message.warning("请选择需要操作的数据");
          return false;
        }
        ids = this.selectTableData.map((item) => {
          return item.itemNameId;
        });
      }
      this.$confirm("是否禁用所选品名?", "提示:")
        .then((respon) => {
          let param = {
            itemNameIds: ids,
          };
          param.sign = this.Tools.getSign(param);
          this.$API.stopItemName(param).then((res) => {
            this.$message.success(res.data.message || "禁用成功");
            // this.leftTableData();
            this.getData();
          });
        })
        .catch((error) => {});
    },
    // 启用
    qiYong(id) {
      this.$confirm("是否启用所选数据?", "提示:")
        .then((respon) => {
          let ids = [id];
          let param = {
            itemCategoryIds: ids,
          };
          param.sign = this.Tools.getSign(param);
          this.$API.startItemCategory(param).then((res) => {
            this.$message.success(res.data.message || "启用成功");
            this.leftTableData();
            this.getData();
          });
        })
        .catch((error) => {});
    },
    // 禁用
    jinYong(id) {
      this.$confirm("是否禁用所选数据?", "提示:")
        .then((respon) => {
          let ids = [id];
          let param = {
            itemCategoryIds: ids,
          };
          param.sign = this.Tools.getSign(param);
          this.$API.stopItemCategory(param).then((res) => {
            this.$message.success(res.data.message || "禁用成功");
            this.leftTableData();
            this.getData();
          });
        })
        .catch((error) => {});
    },

    // 点击选中当前行
    rowclick(row, event, column) {
      this.$refs.mytable.toggleRowSelection(row);
    },
    clerBtns() {
      //  重置按钮
      this.queryData = {
        itemName: "",
        isShow: 1,
        itemCategoryName: "",
      };
    },

    moreParam() {
      this.isHaseMore = !this.isHaseMore;
      if (this.isHaseMore) {
        this.MoreConditions = "收起条件";
      } else {
        this.MoreConditions = "更多条件";
      }

      this.$nextTick(() => {
        this.fetTableHeight();
      });
    },

    // 递归获取部门下所有id
    getDpIds(data) {
      let ids = this.deperIds || [];
      ids.push(data.departmentId);
      let arrs = data.children || [];
      for (var i = 0; i < arrs.length; i++) {
        ids.push(arrs[i].departmentId);
        let arr2 = arrs[i].children || [];
        for (var j = 0; j < arr2.length; j++) {
          this.getDpIds(arr2[j]);
        }
      }
      this.deperIds = ids;
      return this.deperIds;
    },

    queryBtn_Left() {
      this.leftTableData();
    },

    // 左边列表
    leftTableData() {
      this.$API.getLeftItemCategoryList().then((res) => {
        if (res.data.status == "success") {
          let bmList = res.data.result || [];
          // bmList.forEach((item) => {
          //   if (item.parentId == null) {
          //     item.parentId = -1;
          //   }
          // });
          this.menuTableData = bmList;
          // this.menuTableData = this.diGuiGetMeun(bmList, "-1");
        }
      });
    },
    //递归获取数据
    diGuiGetMeun(data, parentId) {
      let tree = [];
      let temp;
      data.forEach((item) => {
        if (item.parentId == parentId) {
          let obj = item;
          obj.departmentName = item.departmentName;
          obj.children = [];
          temp = this.diGuiGetMeun(data, item.departmentId);
          if (temp.length > 0) {
            obj.children = temp;
          }
          tree.push(obj);
        }
      });
      //排序
      for (var i = 0; i < tree.length - 1; i++) {
        for (var j = 0; j < tree.length - 1 - i; j++) {
          if (tree[j].sort > tree[j + 1].sort) {
            let temp = tree[j + 1];
            tree[j + 1] = tree[j];
            tree[j] = temp;
          }
        }
      }
      return tree;
    },

    //菜单修改
    menumodify(row) {
      console.log(row);
      Api.getItemCategoryDetail({ itemCategoryId: row.itemCategoryId }).then(
        (res) => {
          this.dialogTypeR_fz = "edit";
          this.isShowDialog_fz = true;
          this.dialogTitle_fz = "修改货物品类";

          this.dialogForm_fz = res.data.result;
          let showList = this.dialogForm_fz.showList || [];
          if (showList.length == 0) {
            this.dialogForm_fz.showList = [
              {
                languageCode: "zh",
                showName: "",
                operationType: "ADD",
              },
              // {
              //   languageCode: "en",
              //   showName: "",
              //   operationType: "ADD",
              // },
            ];
          } else {
            showList.forEach((item) => {
              item.operationType = "UPDATE";
            });
            this.dialogForm_fz.showList = showList;
          }
        }
      );

      // this.dialogForm_fz = JSON.parse(JSON.stringify(row));
    },
    // 获取冲突品类
    getConflictCategoryTypeList(typeId) {
      this.$API
        .wtl_getConflictCategoryTypeList({ typeId: typeId })
        .then((res) => {
          console.log("res conflictTypeId", res);
          let datas = res.data.result || [];
          let ids = [];
          let conflictTypeIds = [];
          datas.forEach((item) => {
            ids.push(item.typeId);
            let obj = {
              conflictTypeId: item.typeId,
              operationType: "UPDATE",
            };
            conflictTypeIds.push(obj);
          });
          this.dialogFormData.conflictTypeId = ids;
          this.conflictTypeIds = conflictTypeIds;
        });
    },
    // 删除
    delBtns(id) {
      this.$confirm("是否删除该数据?", "提示:")
        .then(() => {
          let ids = [id];
          let param = {
            itemCategoryIds: ids,
          };
          param.sign = this.Tools.getSign(param);
          this.$API.delItemCategory(param).then((res) => {
            if (res.data.status == "success") {
              this.$message.success(res.data.message || "操作成功");
              this.leftTableData();
              this.getData();
            }
          });
        })
        .catch(() => {});
    },
    // 删除品名
    delMore(id) {
      let ids = [];
      if (id) {
        ids.push(id);
      } else {
        if (this.selectTableData.length < 1) {
          this.$message.warning("请选择需要操作的数据");
          return false;
        }
        ids = this.selectTableData.map((item) => {
          return item.itemNameId;
        });
      }

      this.$confirm("是否删除所选数据?", "提示:")
        .then((respon) => {
          let param = {
            itemNameIds: ids,
          };
          param.sign = this.Tools.getSign(param);
          this.$API.delItemName(param).then((res) => {
            this.$message.success(res.data.message || "删除成功");
            this.leftTableData();
            this.getData();
          });
        })
        .catch((error) => {});
    },

    /***
     * ====== 弹窗组件方法 ======
     */

    // 表格选择事件
    handleSelectionChange(e) {
      this.selectTableData = e;
    },

    /**
     * =========================按钮部分的方法=>开始??????????????????????????????????
     */

    //导出操作
    handleCommand_dc(command) {
      if (command == "dc") {
        // 导出
        this.exportBtn();
      } else if (command == "dcmb") {
        // 下载模板
        this.exportTemplateDownload();
      } else if (command == "scmb") {
        // 上传模板
        this.upLoadBtn();
      }
    },

    // ===============================================导出部分=结束======

    /**
     * ******************************计算表格高度**开始*************************
     */
    /***
     * ==============设置表格动态高度==============
     */
    fetTableHeight() {
      // 重置table高度
      this.tabelHeigth = 0;
      // 设置table高度
      let windonHeight =
        document.body.clientHeight || document.documentElement.clientHeight; //页面高度
      let queryHeigth = 40 + 10; //10==margin-top: 10  查询框高度
      let btnListHeight = this.$refs.btnList.offsetHeight; // 按钮列表高度
      if (this.isHaseMore) {
        queryHeigth = this.$refs.queryForm.offsetHeight + 10; //查询框
      }
      let pagsHeigth = 40 + 10; //分页组件高度 20是上下margin
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 60; //60是顶部导航 10是
      let tabHeight = Math.floor(windonHeight - otherHeight) || ""; //

      this.$nextTick(() => {
        this.tableHeigth = tabHeight;
        this.leftHeight = windonHeight - 85 - 10;
        this.$refs.mytable.doLayout();
      });
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    rowClickLeft(tree) {
      console.log(tree, "tree");
      // this.queryData.categoryName = tree.categoryName;
      this.queryData.itemCategoryName = tree.itemCategoryName;
      this.getData();
    },

    // 获取数据
    getData() {
      let that = this;
      let param = {
        isShow: this.queryData.isShow,
        itemCategoryName: this.ChilditemCategoryName
          ? this.ChilditemCategoryName
          : this.queryData.itemCategoryName || "",
        itemName: this.queryData.itemName || "",
        pageStart: this.pageStart,
        pageTotal: this.pageTotal,
      };
      this.$API.getItemNameListV2(param).then((res) => {
        that.tableData = res.data.result.data || [];
        that.pageCount = res.data.result.pageCount || 0;
        that.$nextTick(() => {
          that.fetTableHeight();
        });
      });
    },
    // 获取所属品类类型
    getChongtu() {
      let param = {
        pageStart: 1,
        pageTotal: 200,
      };
      this.$API.wtl_getCategoryTypeList(param).then((res) => {
        this.tableDataALL = res.data.result.data || [];
      });
    },
    // 查询按钮
    queryBtn_ok() {
      this.pageStart = 1;
      this.getData();
    },

    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this;
      this.$API
        .getGroupFindFieldSort({
          groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        })
        .then((res) => {
          let arr = res.data.result || [];
          if (arr.length > 0) {
            if (arr[0].sortObject) {
              that.myTableHeard = JSON.parse(arr[0].sortObject);
              this.sortName = arr[0].sortName;
            }
          } else {
            that.myTableHeard = that.tabelHeadeTitle;
          }
          let totalArr = [];
          //提取需要合计的字段
          for (var i = 0; i < that.myTableHeard.length; i++) {
            if (that.myTableHeard[i].isTotal) {
              totalArr.push(that.myTableHeard[i].field);
            }
          }
          that.totalArr = totalArr;
        });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },
    // 设置模板按钮
    myModel() {
      let that = this;
      that.show_sortTableHeard = true;
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },

    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },
    // 排序==>确认按钮
    confirmOk() {
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let signs = [
        {
          key: "sortName",
          val: this.sortName, //综合查询接口路径
        },
        {
          key: "groupName",
          val: this.pageGroupName,
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = this.Tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      this.$API.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    // 合计
    getSummaries(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },

    //分页
    handleSizeChange(val) {
      this.pageTotal = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageStart = val;
      this.getData();
    },
  },
};
</script>

<style scoped lang='scss'>
.box {
  width: 100%;
  display: flex;
}
.boxleft {
  // width: 40%;
  width: 380px;
  //   min-width: 390px;
  //   height: 88vh;
  overflow: auto;
  margin-right: 10px;
  // border: 1px solid #d7d7d7;
}
.boxright {
  // width: 70%;
  // flex: 1;
  // height: 500px;
  width: calc(100% - 400px);
}
.tablebut {
  margin-left: 15px;
}
.tableBox {
  height: calc(100% - 260px);
}

.colorStyle {
  color: #f00;
}

.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}

.queryCriteria {
  .queryItem_one {
    .queryTextarea {
      border: 1px solid #d7d7d7;
    }
  }
  .timeBox {
    border: 1px solid #d7d7d7;
  }
}

.headerbox {
  width: 100%;
  display: flex;
  justify-content: center;
}

.footerbox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.newForm {
  margin-left: 80px;
  .queryItem1 {
    width: 60%;
    margin-top: 20px;
    display: flex;
    .inputstyle {
      width: 100%;
      display: flex;
    }
  }
}

.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input,
.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input__inner {
  width: 100%;
}

.frominput ::v-deep {
  .el-input__inner {
    height: 30px;
  }
}

.glo_btnList {
  // align-items: center;

  /deep/ .el-input__inner {
    height: 30px;
    line-height: 30px;
  }
}

/deep/ .el-table .cell.el-tooltip {
  white-space: break-spaces;
  min-width: 50px;
  text-align: left;
}

.langCategory {
  display: flex;
  // align-items: center;

  .dialogFormItems {
    min-width: 230px;
  }
}
</style>